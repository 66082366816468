/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */

iframe,
img,
input,
select,
textarea {
  height: auto;
  max-width: 100%;
}
